export const layerNameMappings = {
    ownership: "Ownership",
    zoning: "Zoning",
    conservation_easements: "Conservation Easements",
    control_points_controls: "Control Points Controls",
    ownership_address: "Ownership Address",
    plss_plss_intersected: "PLSS Intersected",
    plss_plss_labels: "PLSS Labels",
    plss_plss_sections: "PLSS Sections",
    plss_plss_townships: "PLSS Townships",
    precincts_polling_centers: "Precincts Polling Centers",
    roads_easements: "Roads Easements",
    roads: "Roads",
    zoning_toj_corp_limit: "TOJ Corporate Limit",
    zoning_toj_zoning_overlay: "TOJ Zoning Overlay",
    zoning_toj_zoning: "TOJ Zoning",
    zoning_zoverlay: "Zoning Overlay",
  };
  