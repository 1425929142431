import React, { useState, useEffect } from 'react';  // Import useState and useEffect
import { Link } from 'react-router-dom';
import './MainHeader.css';
import ContactForm from '../components/ContactForm';
import { useMapContext } from './MapContext';

const MainHeader = ({  }) => {
  const [isContactFormOpen, setIsContactFormOpen] = useState(false); // State to manage contact form visibility
  const { activeTab, setActiveTab } = useMapContext();
  
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleContactClick = () => {
    setIsContactFormOpen(true); // Open contact form
  };

  const handleCloseContactForm = () => {
    setIsContactFormOpen(false); // Close contact form
  };

  return (
    <div className="main-header">
      <Link
        className={`header-tab ${activeTab === 'map' ? 'active' : ''}`}
        onClick={() => handleTabChange('map')}
        to="/map"
      >
        Map
      </Link>
      <Link
        className={`header-tab ${activeTab === 'search' ? 'active' : ''}`}
        onClick={() => handleTabChange('search')}
        to="/search"
      >
        Search
      </Link>
      <Link
        className={`header-tab ${activeTab === 'print' ? 'active' : ''}`}
        onClick={() => handleTabChange('print')}
        to="/print"
      >
        Print
      </Link>
      <Link
        className={`header-tab ${activeTab === 'home' ? 'active' : ''}`}
        onClick={() => handleTabChange('home')}
        to="/"
      >
        Home
      </Link>
      
    </div>
  );
};

export default MainHeader;
