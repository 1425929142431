// src/mapStyles.js

// Define static color map for zoning codes
const zoningColorMaps = {
 toj: {
    "P/SP": "#FF69B4",
    "CR-2": "#FF8C00",
    "OR": "#8A2BE2",
    "CR-1": "#7FFF00",
    "PUD-NL-5": "#6495ED",
    "NL-5": "#FF4500",
    "PUD-NL-3": "#DC143C",
    "NL-2": "#00CED1",
    "PUD-NH-1": "#ADFF2F",
    "NH-1": "#4B0082",
    "NM-2": "#FFD700",
    "PR-SK": "#FF6347",
    "NL-3": "#40E0D0",
    "R": "#FF00FF",
    "NM-1": "#20B2AA",
    "MHP": "#8B0000",
    "NL-1": "#4682B4",
    "BP": "#FFDEAD",
    "CR-3": "#DA70D6",
    "PUD-UR": "#FF1493",
    "P": "#00BFFF",
    "CR-2": "#B22222",
    "DC-2": "#FFD700",
    "DC-1": "#228B22",
    "TS-1": "#D2691E",
    "TS-2": "#FF4500",
    "PUD-NL-3": "#2E8B57",
    "PUD-NM-2": "#9932CC",
    "PUD-NL-2": "#8B4513",
    "PR": "#00FA9A",
    "PUD-NM-2": "#C71585"
  },
  county: {
    'R1': '#1E90FF',        // Blue
    'R2': '#FF7F50',        // Coral
    'R3': '#32CD32',        // Lime Green
    'PUD R1': '#DAA520',    // Goldenrod
    'PUD R2': '#8A2BE2',    // Blue Violet
    'PUD R3': '#D2691E',    // Chocolate
    'PUD - NC': '#FFB6C1',  // Light Pink
    'P': '#FFD700',         // Gold
    'P/SP': '#FF4500',      // Orange Red
    'S': '#A52A2A',         // Brown
    'WC': '#00CED1',        // Dark Turquoise
    'WHB': '#9400D3',       // Dark Violet
    'NC': '#FF69B4',        // Hot Pink
    'NR-1': '#FF6347',      // Tomato
    'AR': '#ADFF2F',        // Green Yellow
    'BP': '#7FFF00',        // Chartreuse
    'PR': '#FF1493',        // Bright Pink
    'R': '#2E8B57'          // Sea Green
  },
    townOverlayColors: {
    'LDG': '#FFA07A',     // Light Salmon
    'DDO-2': '#87CEFA',   // Light Sky Blue
    'DDO-1': '#4682B4',   // Steel Blue
    'NRO': '#3CB371',     // Medium Sea Green
    'OUP': '#FFD700',     // Gold
    'SRO': '#FF6347'      // Tomato
  },
    countyOverlayColors: {
    'LDG 6': '#FF7F50',    // Coral
    'SRO': '#FF6347',      // Tomato
    'LDG 3': '#FFA07A',    // Light Salmon
    'LDG 2': '#FA8072',    // Salmon
    'NRO': '#3CB371',      // Medium Sea Green
    'SRO 3': '#FF4500',    // Orange Red
    'NRO 3': '#2E8B57',    // Sea Green
    'NRO 4': '#8FBC8F',    // Dark Sea Green
    'NRO 2': '#66CDAA',    // Medium Aquamarine
  },
    roadColors: {
    'US': '#FF0000',   // Bright Red for type US Highway
    'WY': '#0000FF',   // Bright Blue Wy Highway/Road
    'CO': '#FFA500',   // Bright Orange for County
    'CM': '#FFA500',   // Bright Orange for County
    'NP': '#FFFF00',   // Bright Yellow for type NP
    'np': '#FFFF00',   // Bright Yellow for type np (same as NP)
    'FS': '#32CD32',   // Bright Magenta for type FS
    'ID': '#FF69B4',   // Bright Red for type ID (Other State)
    'MT': '#FF69B4',   // Bright Pink for type MT (Other State)
    'JA': '#9c8f59',   // Bright Pink for type MT (Other State)
    // Bright Gold for type WY
  }
};

  
  // src/mapStyles.js

// Define static color maps for different zoning codes

  
  // Function to get the color map for a specific zoning layer
  const getColorMapForZoningLayer = (layerName) => {
    
    if (layerName === "zoning") {
      return zoningColorMaps.county;
    } else if (layerName == "zoning_toj_zoning") {
      return zoningColorMaps.toj;
    } else if (layerName === "zoning_toj_zoning_overlay") {
      return zoningColorMaps.townOverlayColors;
    }else if (layerName === "zoning_zoverlay") {
        return zoningColorMaps.countyOverlayColors;
    }else if (layerName === "roads") {
        return zoningColorMaps.roadColors;
      }
  
    return {}; // Default empty object if no color map is found
  };
  
  // Function to parse the description and extract zoning code and objectid
  const parseDescription = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const rows = doc.querySelectorAll('tr');
  
    const properties = {};
    rows.forEach((row) => {
      const cells = row.querySelectorAll('th, td');
      if (cells.length === 2) {
        const key = cells[0].textContent.trim().toLowerCase().replace(/ /g, '_');
        const value = cells[1].textContent.trim();
        properties[key] = value;
      }
    });
  
    return properties;
  };
  
  // General function to get color based on zoning code from the provided color map
  const getZoningColor = (zoningCode, colorMap) => {
    return colorMap[zoningCode] || '#808080'; // Default to gray if zoning code is not mapped
  };
  
  // General function to get the zoning paint style based on features and the zoning layer name
  export const getZoningPaintStyle = (features, layerName) => {

    if (!features || features.length === 0) {
      console.warn(`No zoning features found to parse for layer: ${layerName}.`);
      return {
        'fill-color': '#808080', // Default color
        'fill-opacity': 0.5,
      };
    }
  
    // Get the color map for the given zoning layer
    const colorMap = getColorMapForZoningLayer(layerName);
    let propertyKey = layerName.includes('overlay') ? 'overlay' : 'zoning';
    if (layerName === 'roads') {
    console.log("road property key");
    console.log(propertyKey)
    propertyKey = 'type';
}
  // Create a mapping of "Name" (e.g., "kml_249") to zoning colors
  const featureColorMapping = {};
  features.forEach((feature) => {
    if (feature.properties && feature.properties.description) {
      const parsedProperties = parseDescription(feature.properties.description);
      console.log(propertyKey)
      const zoningValue = parsedProperties[propertyKey];
      const name = feature.properties.Name;

      if (zoningValue && name) {
        const color = getColorMapForZoningLayer(layerName)[zoningValue] || '#808080';
        featureColorMapping[name] = color;
      }
    }
  });
  
    // Create an expression for data-driven styling using "Name"
    const colorExpression = ['match', ['get', 'Name']];
    Object.keys(featureColorMapping).forEach((name) => {
      colorExpression.push(name);
      colorExpression.push(featureColorMapping[name]);
    });
    colorExpression.push('#9c8f59'); // Default color if no match found
  
    if (layerName === 'roads') {
        return {
          'line-color': colorExpression,
          'line-width': 2,
        };
      } else {
        return {
          'fill-color': colorExpression,
          'fill-opacity': 0.5,
        };
      }
  };
  
  // Define styles for different layers
  export const layerStyles = {
    ownership: {
      id: 'ownership-layer',
      type: 'fill', // Keep it as 'fill' to retain clickability on the interior
      'source-layer': 'ownership', // This should match your vector tile metadata
      paint: {
        'fill-color': 'rgba(0, 0, 0, 0.01)', // Very light fill (almost transparent)
        'fill-outline-color': '#000000', // Strong black border to highlight boundaries
        'fill-opacity': 0.5, // Adjust opacity for better visibility (0.2 is very light)
      },
      layout: {
        visibility: 'visible',
      },
    },
    zoning: {
      id: 'zoning-layer',
      type: 'fill',
      'source-layer': 'zoning', // This should match your vector tile metadata
      paint: {}, // This will be filled dynamically
      layout: {
        visibility: 'visible',
      },
    },
    zoning_toj_zoning: {
      id: 'zoning_toj_zoning-layer',
      type: 'fill',
      'source-layer': 'zoning_toj_zoning', // This should match your vector tile metadata
      paint: {}, // This will be filled dynamically
      layout: {
        visibility: 'visible',
      },
    },
    zoning_toj_zoning_overlay: {
        id: 'zoning_toj_zoning_overlay-layer',
        type: 'fill',
        'source-layer': 'zoning_toj_zoning_overlay', // This should match your vector tile metadata
        paint: {}, // This will be filled dynamically
        layout: {
          visibility: 'visible',
        },
      },
      
      zoning_zoverlay: {
    id: 'zoning_zoverlay-layer',
    type: 'fill',
    'source-layer': 'zoning_zoverlay', // This should match your vector tile metadata
    paint: {}, // This will be filled dynamically
    layout: {
        visibility: 'visible',
        },
    },
    zoning_toj_corp_limit: {
        id: 'zoning_toj_corp_limit-layer',
        type: 'fill', // Keep it as 'fill' to retain clickability on the interior
        'source-layer': 'zoning_toj_corp_limit', // This should match your vector tile metadata
        paint: {
          'fill-color': 'rgba(0, 0, 0, 0)', // Fully transparent fill
          'fill-outline-color': '#0000FF', // Blue outline
          'fill-opacity': 1, // No fill opacity (invisible interior)
        },
        layout: {
          visibility: 'visible',
        },
      },
      
      roads: {
        id: 'roads-layer',
        type: 'line', // Add a line layer to handle the thicker border
        'source-layer': 'roads', // This should match your vector tile metadata
        paint: {
          'line-color': '#0000FF', // Blue border color
          'line-width': 2, // Adjust thickness of the outline
        },
        layout: {
          visibility: 'visible',
        },
      },
    
    conservation_easements: {
      id: 'conservation_easements-layer',
      type: 'fill',
      'source-layer': 'conservation_easements',
      paint: {
        'fill-color': '#116e20',
        'fill-opacity': 0.5,
      },
      layout: {
        visibility: 'visible',
      },
    },
  };
  
  // Updated `getLayerStyle` function
  export const getLayerStyle = (layerName, features) => {
    console.log('Getting style for layer:', layerName);
  
    // Get the base style from layerStyles
    let style = layerStyles[layerName];
    console.log('Layer Name:', layerName);
  
    // Define layers that need dynamic styling based on zoning features
    const dynamicZoningLayers = ['zoning', 'zoning_toj_zoning', 'toj_zoning', 'zoning_toj_zoning_overlay','roads', 'zoning_zoverlay'];
    console.log(dynamicZoningLayers.includes(layerName))
    console.log(style)
    console.log(layerName.toLowerCase().includes('plss'))
    if (style) {
        if (dynamicZoningLayers.includes(layerName)) {
            console.log("Applying dynamic style for zoning layer");
            const paint = getZoningPaintStyle(features, layerName);
            console.log('Generated Paint:', paint);
            style = {
                ...style,
                paint, // Use the dynamically created paint style
                source: layerName,
            };
        }
         else {
            style = {
                ...style,
                source: layerName, // Set the source to match the layer name
            };
        }
        return style;
    }
    if (layerName.toLowerCase().includes('plss')) {
        // Special styling for layers that have "plss" in their name
        console.log("Applying special styling for PLSS layer");
        return {
            id: `${layerName}-layer`,
            type: 'fill', // Polygon fill type for clickability
            source: layerName,
            'source-layer': layerName,
            paint: {
                'fill-color': 'rgba(0, 0, 0, 0)', // Fully transparent fill
                'fill-outline-color': '#000000', // Black outline
                'fill-opacity': 1, // No opacity
            },
            layout: {
                visibility: 'visible',
            },
        };
    }

    // If no style is found for the given layer, return a default style for testing purposes
    console.warn(`No style found for layer: ${layerName}. Using default style.`);
  
    // Handling different types of layers: point, line, polygon
    let defaultPaint;
    let layerType;
  
    switch (layerName) {
      case 'ownership_address': // For points
        layerType = 'circle';
        defaultPaint = {
          'circle-radius': 6,
          'circle-color': '#FF0000', // Red for testing visibility
          'circle-stroke-width': 1,
          'circle-stroke-color': '#000000', // Black outline for points
        };
        break;
      case 'roads_easements': // Example for lines
        layerType = 'line';
        defaultPaint = {
          'line-color': '#5f5d60', // Blue for roads
          'line-width': 1,
        };
        break;
      default: // Polygons (default)
        layerType = 'fill';
        defaultPaint = {
          'fill-color': '#FF00FF', // Magenta for testing visibility
          'fill-opacity': 0.5,
          'fill-outline-color': '#000000', // Black border for polygons
        };
        break;
    }
  
    return {
      id: `${layerName}-layer`,
      type: layerType,
      source: layerName,
      'source-layer': layerName,
      paint: defaultPaint,
      layout: {
        visibility: 'visible',
      },
    };
  };
  
  
  
  
  