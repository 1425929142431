
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Intro from './pages/Intro';
import Map from './pages/Mapy';
import Search from './pages/Search';
import SidePanel from "./components/SidePanel"
import { DataProvider } from './assets/DataContext';
import Print from './pages/Print';
import MainHeader from './pages/MainHeader';
import Tutorial from './components/Tutorial'; // Import the new Tutorial page
import Updates from './pages/Updates'; // Import the new Updates page
import './App.css';
import { MapProvider } from './pages/MapContext';

function App() {
  const [activeTab, setActiveTab] = useState('intro');  // Default to 'intro'
  console.log("App is re-rendering");

  return (
    <MapProvider>
      <DataProvider>
        <Router>
          <div className="app-container">
            <MainHeader activeTab={activeTab} onTabChange={setActiveTab} />
            
            {/* Always render the map, so it stays in the background */}
            <div className="map-container">
              <Map
                
              />
            </div>

            {/* Components that overlay the map */}
            <div className="overlay-container">
              <Routes>
                <Route path="/" element={<Intro onStartClick={() => setActiveTab('map')} />} />
                <Route
                  path="/search"
                  element={<Search  onTabChange={setActiveTab} />}
                />
                
                <Route path="/print" element={<Print />} />
                <Route path="/tutorial" element={<Tutorial />} /> {/* New route for Tutorial page */}
                <Route path="/updates" element={<Updates />} /> {/* New route for the Updates page */}
              </Routes>
            </div>
          </div>
        </Router>
      </DataProvider>
    </MapProvider>
  );
}

export default App;